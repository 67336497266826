import React, { useEffect, useState } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { customColors, gradientBackgroundImage } from "../../theme";

import Grid from "@mui/material/Grid2";
import IconTitle from "../IconTitle";
import { AccountBalanceWallet, Done } from "@mui/icons-material";
import { PricingToggle } from "./PricingToggle";
import { PricingCard } from "./PricingCard";
import { PricingCardCompact } from "./PricingCardCompact";
import { packagesPrices } from "./constants";
import { useNavigate } from "react-router-dom";
import { QUERY_PARAMS } from "../../constants";
import { usePayment } from "../../hooks/usePayment";
import { SubscriptionType } from "../../api/services/userSubscriptionService";
import { CycleType } from "../../api/services/userSubscriptionService";
import { Loader } from "../Loader";

// export type BillingType = CycleType.MONTHLY | CycleType.YEARLY;

export type PlanDetails = {
  title: string;
  description: string;
  photos: string;
  price: string;
  pricePhoto: string;
  yearlyPrice: string;
  features: string[];
  billedYearly: string;
  mostPopular: boolean | undefined;
};

const PricingPlan = () => {
  const [billing, setBilling] = useState<CycleType>(CycleType.YEARLY);
  const { t } = useTranslation();

  const paymentAPI = usePayment();

  const pricingPlans = {
    [CycleType.MONTHLY]: [
      {
        title: t("TEXT.PRICING.BASIC_NAME"),
        description: t("TEXT.PRICING.BASIC_DESCRIPTION"),
        plan: SubscriptionType.BASIC,
        photos: packagesPrices[CycleType.MONTHLY].basic.photos.toString(),
        price: `$${packagesPrices[CycleType.MONTHLY].basic.price}`,
        pricePhoto: `$${packagesPrices[CycleType.MONTHLY].basic.pricePerPhoto} / photo`,
        yearlyPrice: `Just $${packagesPrices[CycleType.MONTHLY].basic.yearlyDiscount}/mo with yearly billing`,
        features: t("TEXT.PRICING.BASIC_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: "",
      },
      {
        title: t("TEXT.PRICING.PROFESSIONAL_NAME"),
        description: t("TEXT.PRICING.PROFESSIONAL_DESCRIPTION"),
        plan: SubscriptionType.PROFESSIONAL,
        photos:
          packagesPrices[CycleType.MONTHLY].professional.photos.toString(),
        price: `$${packagesPrices[CycleType.MONTHLY].professional.price}`,
        pricePhoto: `$${packagesPrices[CycleType.MONTHLY].professional.pricePerPhoto} / photo`,
        yearlyPrice: `Just $${packagesPrices[CycleType.MONTHLY].professional.yearlyDiscount}/mo with yearly billing`,
        mostPopular: true,
        features: t("TEXT.PRICING.PROFESSIONAL_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: "",
      },
      {
        title: t("TEXT.PRICING.STANDARD_NAME"),
        description: t("TEXT.PRICING.STANDARD_DESCRIPTION"),
        plan: SubscriptionType.STANDARD,
        photos: packagesPrices[CycleType.MONTHLY].standard.photos.toString(),
        price: `$${packagesPrices[CycleType.MONTHLY].standard.price}`,
        pricePhoto: `$${packagesPrices[CycleType.MONTHLY].standard.pricePerPhoto} / photo`,
        yearlyPrice: `Just $${packagesPrices[CycleType.MONTHLY].standard.yearlyDiscount}/mo with yearly billing`,
        features: t("TEXT.PRICING.STANDARD_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: "",
      },
    ],
    [CycleType.YEARLY]: [
      {
        title: t("TEXT.PRICING.BASIC_NAME"),
        description: t("TEXT.PRICING.BASIC_DESCRIPTION"),

        plan: SubscriptionType.BASIC,
        photos: `${packagesPrices[CycleType.YEARLY].basic.photos}`,
        price: `$${packagesPrices[CycleType.YEARLY].basic.price}`,
        pricePhoto: `$${packagesPrices[CycleType.YEARLY].basic.pricePerPhoto} / photo`,
        yearlyPrice: `Just $${packagesPrices[CycleType.YEARLY].basic.price}/mo with yearly billing`,
        features: t("TEXT.PRICING.BASIC_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: `Billed as $${packagesPrices[CycleType.YEARLY].basic.billedYearly} yearly`,
      },
      {
        title: t("TEXT.PRICING.PROFESSIONAL_NAME"),
        description: t("TEXT.PRICING.PROFESSIONAL_DESCRIPTION"),
        plan: SubscriptionType.PROFESSIONAL,
        photos: packagesPrices[CycleType.YEARLY].professional.photos.toString(),
        price: `$${packagesPrices[CycleType.YEARLY].professional.price}`,
        pricePhoto: `$${packagesPrices[CycleType.YEARLY].professional.pricePerPhoto} / photo`,
        yearlyPrice: `Just $${packagesPrices[CycleType.YEARLY].professional.price}/mo with yearly billing`,
        mostPopular: true,
        features: t("TEXT.PRICING.PROFESSIONAL_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: `Billed as $${packagesPrices[CycleType.YEARLY].professional.billedYearly} yearly`,
      },
      {
        title: t("TEXT.PRICING.STANDARD_NAME"),
        description: t("TEXT.PRICING.STANDARD_DESCRIPTION"),
        plan: SubscriptionType.STANDARD,
        photos: packagesPrices[CycleType.YEARLY].standard.photos.toString(),
        price: `$${packagesPrices[CycleType.YEARLY].standard.price}`,
        pricePhoto: `$${packagesPrices[CycleType.YEARLY].standard.pricePerPhoto} / photo`,
        yearlyPrice: `Just $${packagesPrices[CycleType.YEARLY].standard.price}/mo with yearly billing`,
        features: t("TEXT.PRICING.STANDARD_FEATURES", {
          returnObjects: true,
        }) as string[],
        billedYearly: `Billed as $${packagesPrices[CycleType.YEARLY].standard.billedYearly} yearly`,
      },
    ],
  };

  const plansToShow =
    billing === CycleType.MONTHLY
      ? pricingPlans[CycleType.MONTHLY]
      : pricingPlans[CycleType.YEARLY];

  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const goToCheckout = (billing: CycleType, plan: SubscriptionType) => {
    paymentAPI.handleGetPayment({ plan: plan, cycle: billing });
    const params = new URLSearchParams();
    // params.append(QUERY_PARAMS.billing, billing); // Add your parameters here
    // params.append(QUERY_PARAMS.plan, plan);

    // navigate(`/checkout?${params.toString()}`);
  };

  useEffect(() => {
    if (
      !paymentAPI.isLoading &&
      !paymentAPI.isError &&
      paymentAPI.redirectURL
    ) {
      window.location.href = paymentAPI.redirectURL;
      // window.open(paymentAPI.redirectURL);
    }
  }, [paymentAPI.redirectURL]);

  return paymentAPI.isLoading || paymentAPI.redirectURL ? (
    <Loader />
  ) : (
    <Box
      sx={{
        backgroundImage: gradientBackgroundImage,
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        display: "flex",
        height: "100%",
        margin: "0 auto",
      }}
    >
      <Box
        p={isMobile ? "15px 12px 20px 12px" : 4}
        sx={{
          maxWidth: "1400px",
        }}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between" // This will push items to opposite sides
          alignItems="center" // Vertically aligns the items to the center
          sx={{ marginBottom: isMobile ? "0" : "1rem" }}
        >
          <Box
            alignItems={isMobile ? "center" : "left"}
            sx={{ mb: "10px", mt: "10px" }}
          >
            <IconTitle
              title={t("TEXT.PRICING.CANCEL_ANYTIME")}
              centered={isMobile}
              icon={
                <AccountBalanceWallet
                  fontSize={"medium"}
                  sx={{ color: customColors.gold }}
                />
              }
            />
            <Typography
              variant={isMobile ? "h5" : "h4"}
              component="div"
              fontWeight="bold"
              gutterBottom
              align={isMobile ? "center" : "left"}
              marginTop={"10px"}
            >
              {t("TEXT.PRICING.TITLE")}
            </Typography>
            <Typography
              variant={isMobile ? "body1" : "h5"}
              component="div"
              gutterBottom
              align={isMobile ? "center" : "left"}
              mb={"10px"}
            >
              {t("TEXT.PRICING.SUBTITLE")}
            </Typography>
          </Box>

          <Box sx={{ p: "0 20px 15px 70px" }}>
            <PricingToggle
              billing={billing}
              setBilling={setBilling}
              isMobile={isMobile}
            />
          </Box>
        </Box>
        {/* -------------------------- CARDS ------------------------------- */}
        <Grid container spacing={1}>
          {plansToShow.map((plan: any, index: number) =>
            isMobile ? (
              <PricingCardCompact
                index={index}
                plan={plan}
                billing={billing}
                onPlanSelected={goToCheckout}
              />
            ) : (
              <PricingCard
                index={index}
                plan={plan}
                billing={billing}
                onPlanSelected={goToCheckout}
              />
            )
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default PricingPlan;
