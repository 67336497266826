import React from "react";
import { Box } from "@mui/material";

import HeroSection from "./HeroSection";
import FeaturesSection from "./FeaturesSection";
import { customColors, gradientBackgroundImage } from "../../../theme";

const Header: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: gradientBackgroundImage,
          color: customColors.gold,
          margin: "0",
          border: `1px solid ${customColors.darkGray}`,
        }}
      >
        <HeroSection />
        <FeaturesSection />
      </Box>
    </>
  );
};

export default Header;
