import { User } from "firebase/auth";
import {
  GetUserSubscriptionResponse,
  SubscriptionType,
} from "../api/services/userSubscriptionService";

export const USER_TYPES = {
  NOT_LOGGED_IN: "NOT_LOGGED_IN",
  NO_SUBSCRIPTION: "NO_SUBSCRIPTION",
  MIDDLE_SUBSCRYPTION: "MIDDLE_SUBSCRYPTION",
  HIGHEST_SUBSCRYPTION: "HIGHEST_SUBSCRYPTION",
};

export const getUserType = (user: User | null) => {
  if (user && user.emailVerified) {
    return USER_TYPES.HIGHEST_SUBSCRYPTION;
  }
  return USER_TYPES.NO_SUBSCRIPTION;
};

export const userHasSubscription = (
  subscription: GetUserSubscriptionResponse
) => subscription && subscription.plan !== SubscriptionType.NONE;
