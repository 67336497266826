import { useState } from "react";
import { getJWT } from "../utils/getJWT";
import { getUserSubscription } from "../api/services/userSubscriptionService";
import { GetUserSubscriptionResponse } from "../api/services/userSubscriptionService";

interface SubscriptionResultParams {
  subscriptionId: string;
}

export const useUserSubscription = () => {
  const [subscriptionResult, setSubscriptionResult] =
    useState<GetUserSubscriptionResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleUserSubscription = async () => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const token = await getJWT();

      // Check if the JWT token exists
      if (!token) {
        throw new Error("Missing JWT token");
      }

      // Create headers including the JWT token in the Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Pass headers to the API call and get the response
      const response: GetUserSubscriptionResponse =
        await getUserSubscription(headers);

      // Set success state and store subscription_id from the response
      setIsSuccess(true);
      setSubscriptionResult(response);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleUserSubscription,
    subscriptionResult,
    isLoading,
    isError,
    isSuccess,
  };
};
