import React from "react";
import {
  Done,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import { useState } from "react";
import { customColors } from "../../theme";

interface PricingFeaturesProps {
  features: string[];
}

export const PricingFeatures: React.FC<PricingFeaturesProps> = ({
  features,
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Box sx={{ m: "0px 5px 10px 5px" }}>
      <Button
        variant="text"
        sx={{
          mt: 1,
          //   p: "5px",
          alignContent: "center",
          textAlign: "center",
          width: "100%",
          color: "white",
          fontSize: "12px",
        }}
        onClick={() => setShowMore((prev) => !prev)}
      >
        {showMore ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
        {showMore ? "Show Less" : "Show More"}
      </Button>
      {features.slice(0, showMore ? features.length : 0).map((feature, i) => (
        <Box key={i} sx={{ display: "flex", alignItems: "left", mb: "8px" }}>
          <Done fontSize="small" sx={{ color: customColors.gold }} />
          <Typography variant="body2" sx={{ ml: 1 }}>
            {feature}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
