import { CycleType } from "../../api/services/userSubscriptionService";

export const packagesPrices = {
  [CycleType.MONTHLY]: {
    basic: {
      photos: 6,
      price: 15,
      pricePerPhoto: 2.5,
      yearlyDiscount: 9,
      billedYearly: null,
    },
    standard: {
      photos: 20,
      price: 25,
      pricePerPhoto: 1.25,
      yearlyDiscount: 15,
      billedYearly: null,
    },
    professional: {
      photos: 60,
      price: 49,
      pricePerPhoto: 0.81,
      yearlyDiscount: 29,
      billedYearly: null,
    },
  },
  [CycleType.YEARLY]: {
    basic: {
      photos: 6,
      price: 9,
      pricePerPhoto: 1.5,
      billedYearly: 108,
    },
    standard: {
      photos: 20,
      price: 15,
      pricePerPhoto: 0.75,
      billedYearly: 180,
    },
    professional: {
      photos: 60,
      price: 29,
      pricePerPhoto: 0.48,
      billedYearly: 348,
    },
  },
};
