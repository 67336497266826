import { LockOpenOutlined } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import { t } from "i18next";
import { customColors } from "../../theme";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";

const PhotoAdPanel: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Grid size={{ xs: 12, md: 2.5 }}>
      <Box
        sx={{
          flex: 1,
          padding: "0px",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          alignContent: "left",
          alignItems: "left",
        }}
      >
        <Box>
          <Typography
            sx={{
              border: "1px solid " + customColors.gold,
              display: "inline-block",
              borderRadius: "20px",
              padding: "6px 12px",
              color: customColors.gold,
              boxShadow: "0 0px 6px 4px rgba(235, 196, 127, 0.2)",
              fontSize: "15px",
              textAlign: "center",
              //   letterSpacing: "1px",
              //   fontWeight: 600,
            }}
          >
            {t("TEXT.PHOTOS.STARTING_PRICE")}
          </Typography>

          <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("TEXT.PHOTOS.FULL_VERSION_INCLUDES")}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "10px" }}>
              {t("TEXT.PHOTOS.REMOVE_WATERMARK")}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "5px" }}>
              {t("TEXT.PHOTOS.HIGH_RESOLUTION")}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "5px" }}>
              {t("TEXT.PHOTOS.ALL_ROOM_TYPES")}
            </Typography>
            <Typography variant="body2" sx={{ marginTop: "5px" }}>
              {t("TEXT.PHOTOS.FURNITURE_REMOVAL")}
            </Typography>
          </Box>

          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: customColors.gold,
              color: "#000",
              padding: "10px",
              ":hover": {
                backgroundColor: customColors.lightGold,
                color: "#000",
              },
            }}
            startIcon={<LockOpenOutlined />}
            onClick={() => navigate(`/pricing`)}
          >
            {t("TEXT.PHOTOS.UNLOCK_FEATURES")}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default PhotoAdPanel;
