// CurrentImageContext.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";

// Define the context type
interface CurrentImageContextType {
  currentImage: File | null;
  setCurrentImage: (file: File | null) => void;
}

// Create the context with default values
const CurrentImageContext = createContext<CurrentImageContextType | undefined>(
  undefined
);

// Provider component
export const CurrentImageProvider = ({ children }: { children: ReactNode }) => {
  const [currentImage, setCurrentImage] = useState<File | null>(null);

  return (
    <CurrentImageContext.Provider value={{ currentImage, setCurrentImage }}>
      {children}
    </CurrentImageContext.Provider>
  );
};

// Custom hook for using the context
export const useCurrentImage = () => {
  const context = useContext(CurrentImageContext);
  if (!context) {
    throw new Error("useImage must be used within an ImageProvider");
  }
  return context;
};
