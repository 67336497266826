import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

// Initialize i18n
i18n
  .use(HttpApi) // Use the backend to load translation files
  .use(LanguageDetector) // Automatically detect the user's language
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    fallbackLng: 'en', // Fallback language when the detected language doesn't have translations
    supportedLngs: ['en'], // Supported languages
    debug: false, // Enable this to see logs in the console for debugging
    detection: {
      order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'], // Language detection order
      caches: ['localStorage', 'cookie'], // Where to store the user language preference
    },
    backend: {
      loadPath: '/locales/en/translation.json', // Path to load translation files
    },
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
    react: {
      useSuspense: true, // Enable suspense mode for lazy loading translations
    },
  });

export default i18n;
