import React from "react";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";

interface Props {
  maxHeigthMobile?: string;
  maxHeigth?: string;
}

export const Loader: React.FC<Props> = ({ maxHeigth, maxHeigthMobile }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Box
      sx={{
        p: isMobile ? 2 : 4,
        borderRadius: "10px",
        maxWidth: "1200px",
        minHeight: isMobile ? maxHeigthMobile || "300px" : maxHeigth || "500px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
