import { useState } from "react";
import { getJWT } from "../utils/getJWT";
import {
  getGallery,
  GetGalleryParams,
  GetGalleryResponse,
} from "../api/services/galleryService";

export const useGetGallery = () => {
  const [gallery, setGallery] = useState<GetGalleryResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleGetGallery = async (params: GetGalleryParams) => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const token = await getJWT();

      // Check if the JWT token exists
      if (!token) {
        throw new Error("Missing JWT token");
      }

      // Create headers including the JWT token in the Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response: GetGalleryResponse = await getGallery(params, headers);

      setIsSuccess(true);

      setGallery(response);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleGetGallery,
    gallery,
    isLoading,
    isError,
    isSuccess,
  };
};
