import { Space } from "../../types/api";
import { ENDPOINTS } from "../endpoints";

export const getSpaces = async (): Promise<Space[]> => {
  try {
    const response = await fetch(ENDPOINTS.SPACES, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch spaces");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching spaces:", error);
    throw error;
  }
};
