import React from "react";
import "./Footer.css"; // External CSS file
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer-section">
        <h3 style={{ letterSpacing: "8px" }}>
          {t("FOOTER.LINKS").toUpperCase()}
        </h3>
        <ul>
          <li>
            <a href="/terms-of-use">{t("FOOTER.TERMS")}</a>
          </li>

          <li>
            <a href="/privacy-policy">{t("FOOTER.PRIVACY")}</a>
          </li>
          <li>
            <a href="/privacy-policy">{t("FOOTER.COOKIE")}</a>
          </li>
        </ul>
      </div>

      <div className="footer-section">
        <h3 style={{ letterSpacing: "8px" }}>
          {t("FOOTER.NEWSLETTER").toUpperCase()}
        </h3>
        <form>
          <input
            type="email"
            placeholder="Your Email Address"
            className="newsletter-input"
          />

          <Button variant="outlined" sx={{ borderRadius: "24px" }}>
            {t("FOOTER.SUBSCRIBE_NOW")}
          </Button>
        </form>
      </div>

      <div className="footer-section">
        <h3 style={{ letterSpacing: "8px" }}>
          {t("FOOTER.CONTACT").toUpperCase()}
        </h3>
        <p>
          contact@byteopia-it.com
          <br />
          Building A1, Dubai Digital Park
          <br />
          Dubai Silicon Oasis | Dubai, UAE
        </p>
        <div className="social-icons">
          <a href="#">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
