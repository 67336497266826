import React, { useState } from "react";

import { useSearchParams } from "react-router-dom";
import { QUERY_PARAMS } from "../../constants";
import { Box, Typography } from "@mui/material";
import { customColors } from "../../theme";
import { PricingToggle } from "../Pricing/PricingToggle";
import { packagesPrices } from "../Pricing/constants";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";
import { Payment } from "./Payment";
import CountryRegionForm from "./CountryRegionForm";

// Your publishable key from Stripe dashboard

export const UpdatePaymentDetails = () => {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        alignItems: "center",
        pt: "30px",
        pb: "30px",
        margin: "0 auto",
        alignContent: "center",
      }}
    >
      <Payment actionText="Update" />
    </Grid>
  );
};
