export type ImageComparisonData = {
  beforeURL: string;
  afterURL: string;
  label: string;
};
export const beforeAndAftersGallery: ImageComparisonData[] = [
  //   {
  //     beforeURL:
  //       "https://www.virtualstagingai.app/_next/image?url=%2Frender_examples_5%2Fliving-room-8-empty.webp&w=2048&q=65",
  //     afterURL:
  //       "https://www.virtualstagingai.app/_next/image?url=%2Frender_examples_5%2Fliving-room-8-modern.webp&w=2048&q=65",
  //     label: "Living Room - Modern",
  //   },
  //   {
  //     beforeURL:
  //       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Screenshot%202024-09-12%20at%2011.10.59.png?alt=media&token=a66d11c4-46a6-4439-a526-a135cd1f65cd",
  //     afterURL:
  //       "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Virtual%20Staging%20AI%20-%20Screenshot-2024-09-12-at-11.10.59%20-%20September%2012%2C%202024%2011_11.png?alt=media&token=324fccdc-a577-43c9-a5d5-2e04764c29bf",
  //     label: "Bedroom - Modern",
  //   },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F00.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F02.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    label: "Kitchen - Modern",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F10.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F11.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    label: "Living room - furniture removal",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F20.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F21.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    label: "Dining room - modern",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F30.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F31.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    label: "Home office",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F40.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F41.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    label: "Kids room",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F50.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F51.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    label: "Bedroom - Luxury",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F60.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F61.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    label: "Bedroom - Luxury",
  },
  {
    beforeURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F80.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    afterURL:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/BeforeAfterGallery-JPGs%2F81.webp?alt=media&token=f64d5d2c-711a-4f9d-8dc6-f9af244eee47",
    label: "Living room - Luxury",
  },
];
