import React from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MailOutlineRounded } from "@mui/icons-material";

export const CheckYourEmail: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: { xs: "20px", md: "60px", sx: "100px" },
      }}
    >
      <MailOutlineRounded color="primary" sx={{ fontSize: 80 }} />
      <Typography variant="h4" sx={{ mt: 2, textAlign: "center" }}>
        {t("TEXT.AUTH.CHECK_EMAIL_1")}
      </Typography>
      <Typography variant="body1" sx={{ mt: 3, mb: 2, textAlign: "center" }}>
        {t("TEXT.AUTH.CHECK_EMAIL_2")}
      </Typography>
      <Typography variant="body1" sx={{ mt: 1, mb: 2, textAlign: "center" }}>
        {t("TEXT.AUTH.CHECK_EMAIL_3")}
      </Typography>
    </Box>
  );
};
