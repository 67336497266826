import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ImageIcon from "@mui/icons-material/Image";
import { customColors } from "../../theme";
import { useTranslation } from "react-i18next";
import { beforeAndAftersGallery } from "./imageLinks";
import IconTitle from "../IconTitle";
import BeforeAfterGallery from "./BeforeAfterGallery";
import { useNavigate } from "react-router-dom";

const GalleryPreview: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        padding: { xs: "20px 20px", md: "30px 20px" },
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <Grid
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" }, // 1 column on small screens, 2 columns on medium+
          gap: 4,
          alignItems: "center",
        }}
      >
        {/* Left Side */}
        <Box>
          <Box display="flex" alignItems="center" sx={{ mb: 2, width: "100%" }}>
            <IconTitle
              title={t("NAV.GALLERY")}
              icon={
                <IconButton>
                  <ImageIcon
                    fontSize={"medium"}
                    sx={{ color: customColors.gold }}
                  />
                </IconButton>
              }
            />
          </Box>
          <Typography
            variant={isMobile ? "h5" : "h3"}
            component="div"
            fontWeight="bold"
            gutterBottom
          >
            {t("TEXT.GALLERY_TITLE")}
          </Typography>
          <Typography
            variant={isMobile ? "h4" : "h3"}
            component="div"
            gutterBottom
          >
            {t("TEXT.GALLERY_SUBTITLE")}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {t("TEXT.GALLERY_DESCRIPTION")}
          </Typography>
          <Button
            variant="outlined"
            size="large"
            sx={{ textTransform: "none" }}
            onClick={() => navigate("/gallery")}
          >
            {t("BUTTONS.VIEW_GALLERY")}
          </Button>
        </Box>

        {/* Right Side: Image Comparison */}
        <Box
          sx={{
            width: "100%", // Full width on small screens
            maxWidth: { xs: "100%", md: "600px" }, // Max width on medium+ screens
            margin: "0 auto", // Center it on small screens
            overflow: "hidden", // Prevent overflow issues
          }}
        >
          <BeforeAfterGallery
            beforeAndAfterPicturesData={beforeAndAftersGallery}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default GalleryPreview;
