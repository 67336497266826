import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { PLACEHOLDER_IMAGE } from "../constatnts";
import { customColors } from "../theme";

interface ImageWithLoaderProps {
  displayedImage: string;
}

const ImageWithLoader: React.FC<ImageWithLoaderProps> = ({
  displayedImage,
}) => {
  const [loading, setLoading] = useState(true);

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        minHeight: "500px",
        backgroundColor: customColors.semiDarkGray,
      }}
    >
      {loading && (
        <Skeleton
          variant="rectangular"
          width="100%"
          //   height="100%"
          animation="wave"
          sx={{
            backgroundColor: customColors.semiDarkGray,
          }}
        />
      )}
      <img
        src={displayedImage}
        alt="After"
        style={{
          width: "100%",
          minHeight: "100%",
          objectFit: "cover",
          display: loading ? "none" : "block",
        }}
        onLoad={() => setLoading(false)}
        onError={(e) => {
          setLoading(false);
          (e.target as HTMLImageElement).src = PLACEHOLDER_IMAGE;
        }}
      />
    </Box>
  );
};

export default ImageWithLoader;
