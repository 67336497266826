import { useState, useEffect } from "react";
import { Space } from "../types/api";
import { getSpaces } from "../api/services/spaceService";

export const useFetchSpaces = (): {
  spaces: Space[] | null;
  isError: boolean;
  isLoading: boolean;
} => {
  const [spaces, setSpaces] = useState<Space[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSpaces();
        setSpaces(data);
        setIsError(false); // Reset error state on successful fetch
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { spaces, isError, isLoading };
};
