import { ENDPOINTS } from "../endpoints";

export interface OrderGenerateMoreParams {
  orderId: string;
  remove_furniture: boolean;
  space_name: string;
  style_id: string;
  widget_id: string;
}

// Adjusted to accept headers
export const createOrderGenerateMore = async (
  {
    orderId,
    remove_furniture,
    space_name,
    style_id,
    widget_id,
  }: OrderGenerateMoreParams,
  headers: { Authorization: string }
): Promise<boolean> => {
  const endpoint =
    ENDPOINTS.ORDER + "/" + orderId + ENDPOINTS.ORDER_GENERATE_MORE;

  const formData = new FormData();
  formData.append("remove_furniture", remove_furniture ? "true" : "false");
  formData.append("space_name", space_name);
  formData.append("style_id", style_id);
  formData.append("widget_id", widget_id);

  const payload = {
    remove_furniture: remove_furniture ? true : false,
    space_name: space_name,
    style_id: style_id,
    widget_id: widget_id,
  };
  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Failed to get order generate more");
    }

    // Parse the response JSON and cast it to PostOrderResponse
    const status = response.status;

    return status === 204; // Now it returns the parsed PostOrderResponse object
  } catch (error) {
    console.error("Error getting order generate more:", error);
    throw error;
  }
};
