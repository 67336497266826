import { useState } from "react";
import { getJWT } from "../utils/getJWT";
import {
  getOrderStatus,
  GetOrderStatusResponse,
} from "../api/services/orderStatusService";

interface OrderStatusParams {
  orderId: string;
}

export const useGetOrderStatus = () => {
  const [orderStatus, setOrderStatus] = useState<GetOrderStatusResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleGetOrderStatus = async (params: OrderStatusParams) => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const token = await getJWT();

      // Check if the JWT token exists
      if (!token) {
        throw new Error("Missing JWT token");
      }

      // Create headers including the JWT token in the Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Pass headers to the API call and get the response
      const response: GetOrderStatusResponse = await getOrderStatus(
        params,
        headers
      );

      // Set success state and store order_id from the response
      setIsSuccess(true);
      setOrderStatus(response);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleGetOrderStatus,
    orderStatus,
    isLoading,
    isError,
    isSuccess,
  };
};
