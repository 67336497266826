import React, { useState } from "react";
import { TextField } from "@mui/material";

import { customColors } from "../../theme";

interface CustomEmailInputProps {
  email?: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

export const CustomEmailInput: React.FC<CustomEmailInputProps> = ({
  email,
  setEmail,
}) => {
  const [emailValue, setEmailValue] = useState(email);
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (e: any) => {
    const value = e.target.value;
    setEmailValue(value);

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value && !emailRegex.test(value)) {
      setEmailError("Please enter a valid email address");
      setEmail("");
    } else {
      setEmailError("");
      setEmail(value);
    }
  };

  return (
    <TextField
      label="Email address"
      fullWidth
      margin="normal"
      value={emailValue}
      sx={{
        "& .MuiInputLabel-root": { color: "#b3b3b3" },
        "& .MuiOutlinedInput-root": {
          color: "#ffffff",
          "& fieldset": {
            borderColor: customColors.fieldBorder,
          },
          "&:hover fieldset": {
            borderColor: customColors.gold,
          },
          "&.Mui-focused fieldset": {
            borderColor: customColors.gold,
          },
        },
      }}
      onChange={handleEmailChange}
      error={!!emailError}
      helperText={emailError}
    />
  );
};
