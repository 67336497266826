import React, { useState, useEffect } from "react";
import { Box, CardMedia } from "@mui/material";
import ImageComparison from "./ImageComparison";
import { customColors } from "../../theme";
import { ImageComparisonData } from "./imageLinks";

interface BeforeAfterGalleryProps {
  beforeAndAfterPicturesData: ImageComparisonData[];
  maxWidth?: string;
}

const BeforeAfterGallery: React.FC<BeforeAfterGalleryProps> = ({
  beforeAndAfterPicturesData,
  maxWidth,
}) => {
  const [selectedAfterImage, setSelectedAfterImage] =
    useState<HTMLImageElement | null>(null);
  const [selectedBeforeImageURL, setSelectedBeforeImageURL] = useState<
    string | null
  >(null);
  const [selectedBeforeImage, setSelectedBeforeImage] =
    useState<HTMLImageElement | null>(null);
  const [preloadedImages, setPreloadedImages] = useState<
    Record<string, { before: HTMLImageElement; after: HTMLImageElement }>
  >({});

  // Function to preload a single image
  const preloadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

  useEffect(() => {
    const firstImageBeforeURL = beforeAndAfterPicturesData[0].beforeURL;
    const preloadImages = async () => {
      const images: Record<
        string,
        { before: HTMLImageElement; after: HTMLImageElement }
      > = {};

      for (const image of beforeAndAfterPicturesData) {
        const [beforeImage, afterImage] = await Promise.all([
          preloadImage(image.beforeURL),
          preloadImage(image.afterURL),
        ]);

        if (image.beforeURL === firstImageBeforeURL) {
          setSelectedBeforeImage(beforeImage);
          setSelectedAfterImage(afterImage);
        }

        images[image.beforeURL] = { before: beforeImage, after: afterImage };
        setPreloadedImages(images);
      }
    };
    preloadImages();
  }, [beforeAndAfterPicturesData]);

  useEffect(() => {
    if (!selectedBeforeImage && selectedBeforeImageURL) {
      const preloadedImage = preloadedImages[selectedBeforeImageURL];
      if (preloadedImage) {
        setSelectedBeforeImage(preloadedImage.before);
        setSelectedAfterImage(preloadedImage.after);
      }
    }
  }, [preloadedImages]);

  const handleImageSelect = (image: ImageComparisonData) => {
    const preloadedImage = preloadedImages[image.beforeURL];

    if (preloadedImage) {
      setSelectedBeforeImage(preloadedImage.before);
      setSelectedAfterImage(preloadedImage.after);
    } else {
      setSelectedBeforeImage(null);
      setSelectedAfterImage(null);
    }

    setSelectedBeforeImageURL(image.beforeURL);
  };

  return (
    <Box
      sx={{
        padding: { xs: "0", md: "40px 20px" },
        maxWidth: maxWidth || "1200px",
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: { xs: "100%", md: maxWidth || "600px" },
          margin: "0 auto",
          overflow: "hidden",
        }}
      >
        <ImageComparison
          beforeImage={selectedBeforeImage ? selectedBeforeImage : null}
          afterImage={selectedAfterImage ? selectedAfterImage : null}
        />

        <Box
          sx={{
            display: "flex",
            mt: 2,
            overflowX: "scroll",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          {beforeAndAfterPicturesData.map((image, index) => (
            <Box
              key={index}
              onClick={() => handleImageSelect(image)}
              sx={{
                cursor: "pointer",
                padding: "3px",
                marginRight: "10px",
                border:
                  selectedAfterImage?.src === image.afterURL
                    ? `1px solid ${customColors.gold}`
                    : "2px solid transparent",
                borderRadius: "8px",
                transition: "border 0.2s",
                minWidth: "80px",
                minHeight: "60px",
              }}
            >
              <CardMedia
                component="img"
                image={image.afterURL}
                sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt={image.label}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default BeforeAfterGallery;
