// theme.js or theme.ts

import { createTheme } from "@mui/material/styles";

// Define your custom colors here
const customColors = {
  gold: "#ebc47f",
  shinyGold: "#FFD54F",
  lightGold: "#f5e3c6",
  grayedGold: "#94805d",
  silver: "#c3c3c3",
  white: "#ffffff",
  darkGray: "#282828",
  semiDarkGray: "#333",
  mediumGray: "#3e4347",
  lightGray: "#474b4c",
  // green: "#6ede8a",
  green: "#82DEEA",
  fieldBackground: "#2d2d2d",
  fieldBackgroundLight: "#3b3b3b",
  fieldBorder: "#777777",
  fieldBorderLight: "#b3b3b3",
};

const gradientBackgroundImage = `linear-gradient(130deg, ${customColors.darkGray}, ${customColors.mediumGray})`;
const goldGradient =
  "linear-gradient(135deg, #ebc47f 0%, #e8c890 50%, #d3a65a 100%)";

const blueGradient =
  "linear-gradient(135deg, #4DD0E1 0%, #60CDDB 50%, #2194A2 100%)";
const goldGradientCentral =
  "radial-gradient(circle at top center, " +
  "#e8c890" +
  ", " +
  customColors.gold +
  ")";
const darkGradient =
  "linear-gradient(135deg, #2e2f30 0%, #4f4f4f 50%, #282828 100%)";
const goldShadow = (opacity: string) =>
  "0 0px 15px 6px rgba(235, 196, 127, )" + opacity + ")";
// Create a theme using Material-UI's `createTheme`
const theme = createTheme({
  palette: {
    primary: {
      main: customColors.gold,
    },
    secondary: {
      main: customColors.silver,
    },
    text: {
      primary: customColors.white,
    },
  },
  // You can also define other aspects like typography, spacing, etc.
  //   components: {
  //     MuiButton: {
  //       styleOverrides: {
  //         root: {
  //           borderRadius: '8px', // Example override for button styles
  //         },
  //       },
  //     },
  //   },
});

export {
  theme,
  customColors,
  gradientBackgroundImage,
  goldGradient,
  goldGradientCentral,
  goldShadow,
  blueGradient,
  darkGradient,
};
