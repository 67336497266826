import { ENDPOINTS } from "../endpoints";

interface OrderParams {
  orderId: string;
}

export type GetOrderStatusResponse = {
  percentage: number;
  time_remaining: number;
  is_processed: boolean;
};

// Adjusted to accept headers
export const getOrderStatus = async (
  { orderId }: OrderParams,
  headers: { Authorization: string }
): Promise<GetOrderStatusResponse> => {
  const endpoint = ENDPOINTS.ORDER + "/" + orderId + ENDPOINTS.ORDER_STATUS;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error("Failed to get order status");
    }

    // Parse the response JSON and cast it to PostOrderResponse
    const data = await response.json();

    return data; // Now it returns the parsed PostOrderResponse object
  } catch (error) {
    console.error("Error getting order status:", error);
    throw error;
  }
};
