import { ENDPOINTS } from "../endpoints";

export interface GetGalleryParams {
  page: number;
  itemsPerPage: number;
}

export type GetGalleryResponse = {
  total_count: number;
  orders: GalleryOrder[];
};

export type GalleryOrder = {
  order_id: string;
  before_src: string;
  after_src: string;
  images_count: number;
};

// Adjusted to accept headers
export const getGallery = async (
  { page, itemsPerPage }: GetGalleryParams,
  headers: { Authorization: string }
): Promise<GetGalleryResponse> => {
  try {
    const url = new URL(ENDPOINTS.GALLERY);
    url.searchParams.append("page", page.toString());
    url.searchParams.append("itemsPerPage", itemsPerPage.toString());

    const response = await fetch(url.toString(), {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error("Failed to get gallery");
    }

    // Parse the response JSON and cast it to PostOrderResponse
    const data = await response.json();

    return data; // Now it returns the parsed PostOrderResponse object
  } catch (error) {
    console.error("Error getting gallery:", error);
    throw error;
  }
};
