// firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAUCsAykd-mBYIJXujKAs4JOSI5GZkjKG8",
  authDomain: "virtual-staging-pro-ai.firebaseapp.com",
  projectId: "virtual-staging-pro-ai",
  storageBucket: "virtual-staging-pro-ai.appspot.com",
  messagingSenderId: "262515396438",
  appId: "1:262515396438:web:94d48a05da79d9dd3f10f7",
  measurementId: "G-4R3LCQ0T94",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the storage service
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
