import { auth } from "../firebaseConfig";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";

// Function to get a fresh JWT token, handling both authenticated and anonymous users
// export const getJWT = async (): Promise<string | null> => {
//   let user = auth.currentUser;

//   if (!user) {
//     // If no user is logged in, sign in anonymously
//     try {
//       await signInAnonymously(auth);
//       user = auth.currentUser;
//     } catch (error) {
//       console.error("Error during anonymous sign-in:", error);
//       return null;
//     }
//   }

//   if (user) {
//     // Always return a fresh token
//     return await user.getIdToken(true); // Force token refresh to avoid expiration
//   }

//   return null; // In case something went wrong
// };

export const getJWT = async (): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Return a fresh token to avoid expiration
          const token = await user.getIdToken(true);
          resolve(token);
        } catch (error) {
          console.error("Error fetching token:", error);
          reject(null);
        }
      } else {
        // If no user is logged in, sign in anonymously
        try {
          await signInAnonymously(auth);
          const anonymousUser = auth.currentUser;
          if (anonymousUser) {
            const token = await anonymousUser.getIdToken(true);
          } else {
            reject(null);
          }
        } catch (error) {
          console.error("Error during anonymous sign-in:", error);
          reject(null);
        }
      }
    });
  });
};

export const printJWT = async () => {
  const token = await getJWT();

  // Check if the JWT token exists
  if (!token) {
    throw new Error("Missing JWT token");
  } else {
    console.log("JWT token:", token);
  }
};
