import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Link,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Google as GoogleIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
} from "firebase/auth";
import { auth, googleProvider } from "../../firebaseConfig";
import { CustomButton } from "../CustomButton";

export const Register: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Form state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  // Validation state
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Enable button if everything is valid
  const isFormValid =
    email && password && isEmailValid && isPasswordValid && termsAccepted;

  // Handle form submit for registration
  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage(""); // Reset error message

    if (!isFormValid) return;

    try {
      // Firebase register logic
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(userCredential.user);

      navigate(`/check-your-email`);
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use") {
        setErrorMessage(t("TEXT.AUTH.EMAIL_IN_USE"));
      } else {
        setErrorMessage(t("TEXT.AUTH.REGISTRATION_FAILED"));
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      await signInWithPopup(auth, googleProvider);
      navigate("/");
    } catch (error) {
      console.error("Google Sign-In error", error);
    } finally {
      setLoading(false);
    }
  };

  // Validate email format
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  // Validate password (at least 8 characters and contains a number and a letter)
  const validatePassword = (password: string) => {
    const isStrong =
      password.length >= 8 && /\d/.test(password) && /[a-zA-Z]/.test(password);
    setIsPasswordValid(isStrong);
  };

  // Handle email input change
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  // Handle password input change
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // minHeight: "100vh",
        py: { xs: "20px", md: "60px", sx: "100px" },
      }}
    >
      <Typography component="h1" variant="h5">
        {t("TEXT.AUTH.CREATE_ACCOUNT_TITLE")}
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1, mb: 2 }}>
        {t("TEXT.AUTH.CREATE_ACCOUNT_SUBTITLE")}
      </Typography>
      {errorMessage && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Typography>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ width: "100%", maxWidth: 400 }}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={t("TEXT.AUTH.EMAIL")}
          value={email}
          onChange={handleEmailChange}
          error={!isEmailValid}
          helperText={!isEmailValid ? t("TEXT.AUTH.INVALID_EMAIL") : ""}
          autoComplete="email"
          autoFocus
          sx={{
            input: { color: "white" }, // Text color inside the input
            label: { color: "white" }, // Label text color
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white", // Border color
              },
              "&:hover fieldset": {
                borderColor: "white", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "white", // Border color when focused
              },
            },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={t("TEXT.AUTH.PASSWORD")}
          type="password"
          value={password}
          onChange={handlePasswordChange}
          error={!isPasswordValid}
          helperText={!isPasswordValid ? t("TEXT.AUTH.WEAK_PASSWORD") : ""}
          autoComplete="new-password"
          sx={{
            input: { color: "white" }, // Text color inside the input
            label: { color: "white" }, // Label text color
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white", // Border color
              },
              "&:hover fieldset": {
                borderColor: "white", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "white", // Border color when focused
              },
            },
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              color="primary"
            />
          }
          label={
            <Typography variant="body2">
              {t("TEXT.AUTH.I_HAVE_AGREE")}
              <Link href="/terms-of-service" variant="body2">
                {t("TEXT.AUTH.TERMS_OF_SERVICE")}
              </Link>
            </Typography>
          }
        />
        {/* <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!isFormValid || loading} // Disable button during loading or invalid form
          sx={{
            mb: 2,
            "&.Mui-disabled": {
              backgroundColor: customColors.mediumGray,
              color: customColors.gold,
            },
          }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            t("TEXT.AUTH.CREATE_ACCOUNT")
          )}
 

        </Button> */}

        <CustomButton
          text={t("TEXT.AUTH.CREATE_ACCOUNT")}
          isDisabled={!isFormValid || loading}
          isLoading={loading}
          onClick={handleSubmit}
        />
        <Button
          fullWidth
          variant="outlined"
          startIcon={<GoogleIcon />}
          onClick={handleGoogleSignIn}
          disabled={loading} // Disable Google Sign-In button during loading
          sx={{ mb: 2 }}
        >
          {t("TEXT.AUTH.CONTINUE_WITH_GOOGLE")}
        </Button>
        <Typography variant="body2" align="center">
          {t("TEXT.AUTH.ALREADY_HAVE_ACCOUNT")}{" "}
          <Link href="#" variant="body2" onClick={() => navigate("/login")}>
            {t("TEXT.AUTH.LOG_IN")}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
