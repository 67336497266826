import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { customColors } from "../../theme";
import { FormPanel } from "./UploadPhotoFormPanel";
import { ImagePanel } from "./UploadPhotoImagePanel";
import { Loader } from "../Loader";
import { ErrorComponent } from "../ErrorComponent";

import { useCurrentImage } from "../../context/CurrentImageContext";
import { useCreateOrder } from "../../hooks/useCreateOrder";
import { useNavigate } from "react-router-dom";
import { QUERY_PARAMS } from "../../constants";

interface Props {
  onPhotoSubmited?: (orderId: string) => void;
}

const UploadPhoto: React.FC<Props> = ({ onPhotoSubmited }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const orderAPI = useCreateOrder();

  const [orderSent, setOrderSent] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { setCurrentImage } = useCurrentImage();

  const navigate = useNavigate();

  if (orderAPI.isSuccess && orderAPI.orderId) {
    if (onPhotoSubmited) {
      onPhotoSubmited(orderAPI.orderId);
    } else {
      const params = new URLSearchParams();
      params.append(QUERY_PARAMS.order, orderAPI.orderId);
      navigate(`/photo?${params.toString()}`);
    }
  }

  // Handle file upload from device
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedImage(file); // Store the image as File
      setCurrentImage(file);
    }
  };

  // Handle image selection from example images
  const handleImageSelect = (imageUrl: string) => {
    fetch(imageUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "example-image.jpg", {
          type: "image/jpeg",
        });
        setSelectedImage(file); // Store the selected gallery image as a File
        setCurrentImage(file);
      });
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setCurrentImage(null);
  };

  // "65b4e95ec2b80c47dfe933ab"
  const handleCreateOrder = (
    removeFurniture: boolean,
    spaceName: string,
    styleId: string,
    widgetId?: string
  ) => {
    setOrderSent(true);
    selectedImage &&
      orderAPI.handleCreateOrder({
        image: selectedImage,
        removeFurniture,
        spaceName,
        styleId,
        widgetId: widgetId || "65b4e95ec2b80c47dfe933ab",
      });
  };

  const imagePanel = ImagePanel({
    selectedImage,
    handleRemoveImage,
    handleImageSelect,
    handleFileUpload,
  });

  const formPanel = (
    <Grid size={{ xs: 12, md: 4 }}>
      {" "}
      {FormPanel({
        handleCreateOrder,
        isDisabled: !selectedImage,
        // onSubmit: () => setOrderSent(true),
        setIsError,
      })}{" "}
    </Grid>
  );

  return (
    <Box
      sx={{
        p: isMobile ? 2 : 4,
        backgroundColor: customColors.semiDarkGray,
        color: "#fff",
        borderRadius: "10px",
        maxWidth: "1200px",
        minHeight: isMobile ? "300px" : "500px",
        margin: "0 auto",
      }}
    >
      {orderSent ? (
        <Loader />
      ) : isError || orderAPI.isError ? (
        <ErrorComponent />
      ) : (
        <Grid container spacing={isMobile ? 0 : 4}>
          {isMobile ? (
            <>
              {imagePanel} {formPanel}
            </>
          ) : (
            <>
              {formPanel}
              {imagePanel}{" "}
            </>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default UploadPhoto;
