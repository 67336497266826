import React from "react";
import { Box, Typography, Button, Card, CardContent } from "@mui/material";
import { customColors, goldGradientCentral } from "../../theme";
import { t } from "i18next";
import { PricingFeatures } from "./PricingFeatures";

import Grid from "@mui/material/Grid2";
import { CycleType } from "../../api/services/userSubscriptionService";
import { SubscriptionType } from "../../api/services/userSubscriptionService";

export type pricingPlan = {
  title: string;
  description: string;
  plan: SubscriptionType;
  photos: string;
  price: string;
  pricePhoto: string;
  yearlyPrice: string;
  features: string[];
  billedYearly: string;
  mostPopular?: boolean;
};
interface PricingCardsProps {
  index: number;
  plan: pricingPlan;
  billing: CycleType;
  onPlanSelected: (billing: CycleType, plan: SubscriptionType) => void;
}

export const PricingCardCompact: React.FC<PricingCardsProps> = ({
  plan,
  index,
  billing,
  onPlanSelected,
}) => {
  return (
    <Grid size={{ xs: 12 }} key={index}>
      <Card
        sx={{
          padding: { xs: "8px 8px 0 8px", md: "1rem 1rem 0 1rem" },
          position: "relative",
          borderRadius: "26px",
          minHeight: { xs: "200px", md: "650px" },
          backgroundColor: plan.mostPopular
            ? // ? "#233538"
              customColors.darkGray
            : customColors.darkGray,
          border: plan.mostPopular
            ? "1px solid " + customColors.gold
            : "2px solid " + customColors.mediumGray,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.8)",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          alignContent: "left",
          margin: "5px 15px",
        }}
      >
        <CardContent
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {/* {" TITLE "} */}
          <Typography
            sx={{
              border:
                "1px solid " +
                (plan.mostPopular ? customColors.gold : customColors.green),
              display: "inline-block",
              borderRadius: "20px",
              padding: "5px 8px",
              color: plan.mostPopular ? customColors.gold : customColors.green,
              boxShadow: plan.mostPopular
                ? "0 0px 6px 4px rgba(235, 196, 127, 0.2)"
                : "0 0px 6px 4px rgba(77, 208, 225, 0.2)",
              // backgroundColor: "rgba(77, 208, 225, 0.1)",
              fontSize: "13px",
              textAlign: "flex-start",
              letterSpacing: "3px",
              // marginTop: "12px",
              fontWeight: 900,
            }}
          >
            {plan.title.toUpperCase()}
          </Typography>

          {/* Photos Per Month */}
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              color: plan.mostPopular ? customColors.gold : "white",
              marginBottom: "0px",
              marginTop: "10px",
            }}
          >
            <span style={{ fontSize: "36px" }}>{plan.photos}</span>
            <span
              style={{
                fontSize: "20px",
                marginLeft: "4px",
                position: "relative",
                bottom: "-3px",
              }}
            >
              {t("TEXT.PRICING.PHOTOS_PER_MONTH")}
            </span>
          </Typography>

          {/* Description */}
          <Typography
            variant={"body1"}
            color="white"
            sx={{
              mb: 1,
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            {plan.description}
          </Typography>

          {/* Price Per Month */}
          <Typography
            variant="h2"
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              color: plan.mostPopular ? customColors.gold : "white",
              fontSize: "24px",
            }}
          >
            <span>{plan.price}</span>
            <span
              style={{
                fontSize: "20px",
                marginLeft: "4px",
                position: "relative",
                bottom: "0",
              }}
            >
              {t("TEXT.PRICING.PER_MONTH")}
            </span>
          </Typography>

          {/* Price Per Photo */}
          <Typography
            variant="body1"
            color="white"
            sx={{
              mb: "10px",
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            {plan.pricePhoto}
          </Typography>

          {/* Yearly Billing Banner */}
          {billing === CycleType.YEARLY ? (
            <></>
          ) : (
            <Box
              sx={{
                backgroundColor: "rgba(77, 208, 225, 0.1)",
                borderRadius: "16px",
                padding: "6px 12px",
                color: customColors.green,
                fontWeight: "500",
                fontSize: "14px",
                textAlign: "center",
                // mb: "6px",
              }}
            >
              {plan.yearlyPrice}
            </Box>
          )}
        </CardContent>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: { xs: 0, md: 2 },
            mb: { xs: 0, md: 3 },
            mr: { xs: 2, md: 0 },
            ml: { xs: 2, md: 0 },
            minWidth: { xs: "100px", md: "100%" },
            borderRadius: "12px",
            textAlign: "center",
            backgroundImage: goldGradientCentral,
            fontSize: "13px",
          }} // Center button
          onClick={() => onPlanSelected(billing, plan.plan)}
        >
          <b>{t("BUTTONS.GET_STARTED")}</b>
        </Button>
        <PricingFeatures features={plan.features} />
        {billing === CycleType.YEARLY && (
          <Typography
            fontSize={"12px"}
            align="center"
            color={customColors.grayedGold}
            sx={{ mt: "0", mb: 2, textAlign: "center" }} // Center text horizontally
          >
            {plan.billedYearly}
          </Typography>
        )}
      </Card>
    </Grid>
  );
};
