// import {
//   ArrowDownward,
//   Done,
//   KeyboardArrowDownRounded,
//   KeyboardArrowUpRounded,
// } from "@mui/icons-material";
// import {
//   Box,
//   Typography,
//   Button,
//   styled,
//   ToggleButtonGroup,
//   ToggleButton,
// } from "@mui/material";
// import { useState } from "react";
// import { customColors, goldGradientCentral } from "../theme";
// import { t } from "i18next";
// import { BillingType } from "./PricingPage";

// const PricingToggletyled = styled(ToggleButtonGroup)(({ theme }) => ({
//   backgroundColor: customColors.darkGray,
//   border: "1px solid " + customColors.lightGray,
//   color: customColors.gold,
//   borderRadius: "16px",
//   //   boxShadow: "0 0px 15px 6px rgba(226, 226, 226, 0.1)",
//   padding: "4px",
//   transition: "all 0.3s ease-in-out",
//   "& .MuiToggleButton-root": {
//     padding: "5px 20px",
//     fontWeight: 600,
//     borderRadius: "14px",
//     fontSize: "12px",
//     color: "#fff",
//     transition: "all 0.2s ease-in-out",
//     border: "none",
//     "&:hover": {
//       backgroundColor: customColors.grayedGold,
//       boxShadow: "0 6px 16px rgba(0, 0, 0, 0.1)",
//     },
//     "&.Mui-selected": {
//       backgroundImage: goldGradientCentral,
//       color: "black",
//     },
//     "&.Mui-selected:hover": {
//       backgroundColor: customColors.lightGold,
//       boxShadow: "0 6px 16px rgba(255, 204, 0, 0.2)",
//     },
//   },
// }));

// interface PricingToggleProps {
//   billing: BillingType;
//   setBilling: React.Dispatch<React.SetStateAction<BillingType>>;
//   isMobile: boolean;
// }

// export const PricingToggle: React.FC<PricingToggleProps> = ({
//   billing,
//   setBilling,
//   isMobile,
// }) => {
//   const handleBillingChange = (
//     event: React.MouseEvent<HTMLElement>,
//     newBilling: BillingType
//   ) => {
//     if (newBilling !== null) {
//       setBilling(newBilling);
//     }
//   };

//   return (
//     <PricingToggletyled
//       value={billing}
//       exclusive
//       onChange={handleBillingChange}
//       aria-label="pricing toggle"
//       sx={{ marginBottom: isMobile ? "15px" : "30px" }}
//     >
//       <ToggleButton
//         value={"monthly"}
//         aria-label="monthly"
//         sx={{ marginRight: "5px", fontSize: "12px" }}
//       >
//         {t("TEXT.PRICING.MONTHLY.TITLE")}
//       </ToggleButton>
//       <ToggleButton value={CycleType.YEARLY} aria-label=CycleType.YEARLY>
//         {t("TEXT.PRICING.YEARLY.TITLE")}
//         <span
//           style={{
//             marginLeft: "10px",
//             backgroundColor: customColors.green,
//             color: "#2C2C2E",
//             padding: "2px 8px",
//             borderRadius: "16px",
//             fontWeight: 700,
//             fontSize: "12px",
//           }}
//         >
//           {t("TEXT.PRICING.40_OFF")}
//         </span>
//       </ToggleButton>
//     </PricingToggletyled>
//   );
// };
import React from "react";
import { Box, Typography, styled, Switch } from "@mui/material";

import { customColors } from "../../theme";
import { t } from "i18next";

import { CycleType } from "../../api/services/userSubscriptionService";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  display: "flex",
  //   backgroundColor: customColors.lightGold,
  //   borderRadius: "12px",
  "& .MuiSwitch-switchBase": {
    padding: 3,
    color: "#fff",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        background: "linear-gradient(45deg, #4DD0E1, #4DD0E1)",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 20,
    height: 20,
    boxShadow: "0 3px 5px rgba(255,255,255,.3)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: customColors.lightGray,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface PricingToggleProps {
  billing: CycleType;
  setBilling: React.Dispatch<React.SetStateAction<CycleType>>;
  isMobile: boolean;
}

export const PricingToggle: React.FC<PricingToggleProps> = ({
  billing,
  setBilling,
  isMobile,
}) => {
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBilling(event.target.checked ? CycleType.YEARLY : CycleType.MONTHLY);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        padding: "0px 0px",
        borderRadius: "20px",
        // margin: "15px 0px 15px 15px ",
        minWidth: "300px",
      }}
    >
      <Typography
        sx={{
          color: billing === CycleType.MONTHLY ? "#fff" : "#888",
          fontWeight: billing === CycleType.MONTHLY ? 700 : 400,
          marginRight: "15px",
        }}
      >
        {t("TEXT.PRICING.MONTHLY.TITLE")}
      </Typography>

      <CustomSwitch
        checked={billing === CycleType.YEARLY}
        onChange={handleSwitchChange}
      />

      <Box display="flex" alignItems="center">
        <Typography
          sx={{
            color: billing === CycleType.YEARLY ? "#fff" : "#888",
            fontWeight: billing === CycleType.YEARLY ? 700 : 400,
            marginRight: "8px",
            marginLeft: "15px",
          }}
        >
          {t("TEXT.PRICING.YEARLY.TITLE")}
        </Typography>
        <Box
          sx={{
            backgroundColor: "rgba(77, 208, 225, 0.2)",
            color: "#4DD0E1",
            padding: "4px 8px",
            borderRadius: "10px",
            fontWeight: 700,
            fontSize: "12px",
          }}
        >
          {t("TEXT.PRICING.40_OFF")}
        </Box>
      </Box>
    </Box>
  );
};
