import React, { CSSProperties } from "react";
import { useParams } from "react-router-dom";
import { customColors } from "../../theme";
import { ErrorOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type PaymentStatusParams = {
  status?: string;
};

const PaymentStatus: React.FC = () => {
  const { status } = useParams<PaymentStatusParams>();
  const isSuccess = status === "success";
  const { t } = useTranslation();

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "40px",
      //   backgroundColor: customColors.semiDarkGray,
      minHeight: "500px",
    } as CSSProperties,
    box: {
      textAlign: "center" as CSSProperties["textAlign"],
      padding: "40px",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      width: "400px",
    } as CSSProperties,
    successBox: {
      backgroundColor: customColors.semiDarkGray,
    } as CSSProperties,
    errorBox: {
      backgroundColor: customColors.semiDarkGray,
    } as CSSProperties,
    heading: {
      margin: 0,
      fontSize: "2rem",
    } as CSSProperties,
    paragraph: {
      margin: "1rem 0",
      fontSize: "1rem",
    } as CSSProperties,
    button: {
      marginTop: "1.5rem",
      padding: "0.75rem 1.5rem",
      backgroundColor: customColors.gold,
      color: "#000",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "1rem",
      transition: "background-color 0.3s ease",
    } as CSSProperties,
    buttonHover: {
      backgroundColor: customColors.grayedGold,
    } as CSSProperties,
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          ...styles.box,
          ...(isSuccess ? styles.successBox : styles.errorBox),
        }}
      >
        {isSuccess ? (
          <>
            <h1 style={styles.heading}>
              {t("TEXT.PAYMENT_STATUS.SUCCESS_TITLE")}
            </h1>
            <p style={styles.paragraph}>
              {t("TEXT.PAYMENT_STATUS.SUCCESS_TEXT")}
            </p>
          </>
        ) : (
          <>
            <h1 style={styles.heading}>
              {t("TEXT.PAYMENT_STATUS.FAIL_TITLE")}
            </h1>
            <p style={styles.paragraph}>{t("TEXT.PAYMENT_STATUS.FAIL_TEXT")}</p>
          </>
        )}
        <button
          style={styles.button}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              styles.buttonHover.backgroundColor || "")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = styles.button
              .backgroundColor as string)
          }
          onClick={() => (window.location.href = "/")}
        >
          {t("TEXT.PAYMENT_STATUS.ACTION_TEXT")}
        </button>
      </div>
    </div>
  );
};

export default PaymentStatus;
