import React, { createContext, useContext, useEffect, useState } from "react";
import { getJWT } from "../utils/getJWT";
import { getUserSubscription } from "../api/services/userSubscriptionService";
import { GetUserSubscriptionResponse } from "../api/services/userSubscriptionService";

interface SubscriptionContextType {
  subscriptionResult: GetUserSubscriptionResponse | undefined;
  isLoading: boolean;
  isError: boolean;
  refetchSubscription: () => void;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(
  undefined
);

export const SubscriptionProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [subscriptionResult, setSubscriptionResult] = useState<
    GetUserSubscriptionResponse | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchSubscription = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const token = await getJWT();
      if (!token) throw new Error("Missing JWT token");

      const headers = { Authorization: `Bearer ${token}` };
      const response: GetUserSubscriptionResponse =
        await getUserSubscription(headers);
      setSubscriptionResult(response);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!subscriptionResult) fetchSubscription();
  }, [subscriptionResult]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionResult,
        isLoading,
        isError,
        refetchSubscription: fetchSubscription,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

// Custom hook to use SubscriptionContext
export const useSubscription = (): SubscriptionContextType => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error(
      "useSubscription must be used within a SubscriptionProvider"
    );
  }
  return context;
};
