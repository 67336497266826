export type Review = {
  name: string;
  location: string;
  review: string;
  stars: number;
  company?: string;
  image?: string;
  companyLogo?: string;
};

export const reviews: Review[] = [
  {
    name: "James T.",
    location: "Miami, Florida",
    review:
      "I had a listing that did not include any furniture. With your exceptional service, I staged the property and even disclosed on the listing that it had been virtually staged. The property sold almost immediately.",
    stars: 5,
    company: "Summit Estates Realty",
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fm1.png?alt=media&token=a924ad15-b425-4f11-8bed-7afa128370dc",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F3.png?alt=media&token=8e224d70-0f5d-4716-b174-9c7c5ec57a33",
  },
  {
    name: "Diane T.",
    location: "Denver, CO",
    review:
      "I couldn’t believe it wasn’t real furniture! My clients were thrilled, and the staged photos brought in so much more interest.",
    stars: 5,
    company: "BlueKey Properties",
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fw3.png?alt=media&token=4fad087c-0729-42c6-bc64-a57000841704",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F4.png?alt=media&token=368adba1-219f-48bd-bb69-b2c6dbb09efc",
  },
  {
    name: "Mike S.",
    location: "San Francisco, CA",
    review:
      "The transformation was incredible! My empty listing looked like a designer had staged it in person. It sold within two weeks!",

    stars: 5,
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fm4.png?alt=media&token=957a5027-45cb-4f1a-af08-33e504e51b07",
  },
  {
    name: "David H.",
    location: "Seattle, WA",
    review:
      "The buyers were so impressed with the staged photos they asked if the furniture could be included! Highly recommend. So Realistic, It's Unbelievable",
    stars: 5,
    company: "Elite Home Advisors",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F1.png?alt=media&token=8e156a48-0e86-4429-bcdb-3c5bc5cfc1de",
  },
  {
    name: "Briana G.",
    location: "Atlanta, GA",
    review:
      "The AI staging gave the space a modern, clean look. We went from minimal inquiries to multiple offers in just days!",
    stars: 5,
    company: "Elite Home Advisors",
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fw1.png?alt=media&token=f5895e90-2abe-4e7e-9ede-8539076ee76a",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F5.png?alt=media&token=694ed658-68ea-44c8-a37a-a9ad03c5fff9",
  },
  {
    name: "Patricia J.",
    location: "NY, US",
    review:
      "Virtual StagingAI is simple to use and provided excellent results. Once I virtually staged one of my properties, I had showing inquiries almost immediately. The staging made a huge difference in my marketing efforts.",

    stars: 5,
    company: "Pinnacle Property Solutions",
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fw2.png?alt=media&token=dcc75bd0-480a-4e90-a5e7-2d44139f2f1e",
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F7.png?alt=media&token=60a488ef-3a8b-4382-9747-fff7ce5b9746",
  },
  {
    name: "Kelly P.",
    location: "Dallas, TX",
    review:
      "Time and Money Saver! This service saved me time and money. No need to rent furniture, and the AI-generated staging looked so real.",
    stars: 4,
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F2.png?alt=media&token=64f8dbc2-b8ad-4965-bd78-4aea375bf768",
  },
  {
    name: "Laura D.",
    location: "Orlando, FL",
    review:
      "The staging made my listing look like a dream home. The furniture and decor were perfectly tailored to the space, and the photos were so inviting that we had multiple showings within days. Buyers couldn’t stop complimenting the presentation!",
    stars: 5,
    companyLogo:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateLogos%2F6.png?alt=media&token=33b3d5de-b469-4294-865c-0b464b4b8808",
  },
  {
    name: "Michael S.",
    location: "Portland, OR",
    review:
      "I had a tight deadline to list a property, and the AI staging was done faster than expected. The quality didn’t suffer at all—every detail was carefully thought out, and the result was stunning. We had buyers lining up for viewings the day it went live!",
    stars: 5,
    company: "Horizon Realty Group",
    image:
      "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/RealEstateAgents%2Fm6.png?alt=media&token=26a60240-e635-47af-9777-97b6f62bac66",
    companyLogo:
      "https://logowik.com/content/uploads/images/361_realestate_logo.jpg",
  },
];
