import React, { useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  Link,
} from "@mui/material";
import { customColors } from "../../theme";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next"; // Assuming you are using i18next

const stripePromise = loadStripe("your-publishable-key-here");

interface Props {
  actionText?: string;
}

const PaymentForm: React.FC<Props> = ({ actionText }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [showVatForm, setShowVatForm] = useState(false);
  const [vatNumber, setVatNumber] = useState("");

  const handleVatToggle = () => {
    setShowVatForm((prev) => !prev);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        console.error("[Error]", error);
      } else {
        console.log("[PaymentMethod]", paymentMethod);
      }
    }
  };

  const textColor = "#999";

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 600,
        margin: { xs: "0px 20px", md: "0 auto" },
        padding: 4,
        borderRadius: 2,
        backgroundColor: customColors.semiDarkGray,
        boxShadow: 5,
        color: "white",
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ color: customColors.gold }}>
        {t("TEXT.PAYMENT.PAYMENT_DETAILS")}
      </Typography>

      <Button
        variant="contained"
        fullWidth
        sx={{
          my: 2,
          bgcolor: customColors.fieldBackgroundLight,
          boxShadow: 2,
          "&:hover": { bgcolor: customColors.gold },
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Logos%2FPayPal.svg?alt=media&token=112f8e91-f3d5-468a-8e2e-229b4653caa8"
          alt="PayPal"
          style={{
            maxWidth: "100px",
          }}
        />
      </Button>
      <Button
        variant="contained"
        fullWidth
        sx={{
          mb: 2,
          bgcolor: customColors.fieldBackgroundLight,
          boxShadow: 2,
          "&:hover": { bgcolor: customColors.gold },
        }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/Logos%2FGoogle_Pay_Logo.svg?alt=media&token=8231bda7-e48d-49f3-9661-43a0272b4ddd"
          alt="Google Pay"
          style={{ maxWidth: "100px" }}
        />
      </Button>

      <Divider
        sx={{
          my: 2,
          borderColor: customColors.fieldBorderLight,
          color: customColors.fieldBorderLight,
        }}
      >
        {t("TEXT.PAYMENT.OR_PAY_BY_CARD")}
      </Divider>

      <Box
        sx={{
          border: `1px solid ${customColors.fieldBorder}`,
          padding: "10px",
          borderRadius: "4px",
          backgroundColor: customColors.semiDarkGray,
          marginBottom: "20px",
        }}
      >
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#ffffff",
                "::placeholder": {
                  color: customColors.fieldBorderLight,
                  //   backgroundColor: "red",
                },
                // backgroundColor: "red",
              },
              invalid: {
                color: "red",
              },
            },
          }}
        />
      </Box>

      <TextField
        fullWidth
        label={t("TEXT.PAYMENT.NAME_ON_CARD")}
        variant="outlined"
        InputLabelProps={{ style: { color: customColors.fieldBorderLight } }}
        sx={{
          my: 2,
          input: { color: "#ffffff" },
          fieldset: { borderColor: customColors.fieldBorder },
        }}
      />

      <Box display="flex" justifyContent="space-between">
        <TextField
          label={t("TEXT.PAYMENT.EXPIRATION_DATE")}
          placeholder="MM / YY"
          InputLabelProps={{ style: { color: customColors.fieldBorderLight } }}
          sx={{
            width: "48%",
            input: { color: "#ffffff" },
            fieldset: { borderColor: customColors.fieldBorder },
          }}
        />
        <TextField
          label={t("TEXT.PAYMENT.SECURITY_CODE")}
          placeholder="CVV"
          InputLabelProps={{ style: { color: customColors.fieldBorderLight } }}
          sx={{
            width: "48%",
            input: { color: "#ffffff" },
            fieldset: { borderColor: customColors.fieldBorder },
          }}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
        <Link
          component="button"
          variant="body2"
          onClick={handleVatToggle}
          sx={{ color: customColors.gold }}
        >
          {t("TEXT.PAYMENT.ADD_VAT_NUMBER")}
        </Link>
      </Box>

      {showVatForm && (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid size={12}>
            <TextField
              fullWidth
              label={t("TEXT.PAYMENT.VAT_NUMBER")}
              value={vatNumber}
              onChange={(e) => setVatNumber(e.target.value)}
              InputLabelProps={{ style: { color: textColor } }}
              sx={{
                input: { color: textColor },
                fieldset: { borderColor: textColor },
              }}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              fullWidth
              label={t("TEXT.PAYMENT.COMPANY_NAME")}
              InputLabelProps={{ style: { color: textColor } }}
              sx={{
                input: { color: textColor },
                fieldset: { borderColor: textColor },
              }}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              fullWidth
              label={t("TEXT.PAYMENT.STREET")}
              InputLabelProps={{ style: { color: textColor } }}
              sx={{
                input: { color: textColor },
                fieldset: { borderColor: textColor },
              }}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              fullWidth
              label={t("TEXT.PAYMENT.TOWN_CITY")}
              InputLabelProps={{ style: { color: textColor } }}
              sx={{
                input: { color: textColor },
                fieldset: { borderColor: textColor },
              }}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              fullWidth
              label={t("TEXT.PAYMENT.STATE_COUNTY")}
              InputLabelProps={{ style: { color: textColor } }}
              sx={{
                input: { color: textColor },
                fieldset: { borderColor: textColor },
              }}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              fullWidth
              label={t("TEXT.PAYMENT.COUNTRY")}
              InputLabelProps={{ style: { color: textColor } }}
              sx={{
                input: { color: textColor },
                fieldset: { borderColor: textColor },
              }}
            />
          </Grid>
          <Grid size={12} display="flex" justifyContent="space-between">
            <Button variant="contained" sx={{ bgcolor: customColors.gold }}>
              {t("TEXT.PAYMENT.APPLY")}
            </Button>
            <Button
              variant="outlined"
              sx={{ color: customColors.gold, borderColor: customColors.gold }}
              onClick={handleVatToggle}
            >
              {t("TEXT.PAYMENT.CANCEL")}
            </Button>
          </Grid>
        </Grid>
      )}

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mt: 3, bgcolor: customColors.gold }}
      >
        {actionText || t("TEXT.PAYMENT.SUBSCRIBE_NOW")}
      </Button>
    </Box>
  );
};

export const Payment: React.FC<Props> = ({ actionText }) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm actionText={actionText} />
    </Elements>
  );
};
