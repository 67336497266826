import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  DownloadRounded,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";
import { customColors } from "../../theme";
import { PLACEHOLDER_IMAGE } from "../../constatnts";
import ImageWithLoader from "../ImageWithLoader";

interface ImageProps {
  id: string;
  image_src: string;
}

interface DisplayPanelProps {
  result: { image: ImageProps[] };
  displayedImage: string;
  setDisplayedImage: (image: string) => void;
  currentImageProps?: { style_name: string; space_name: string };
  isLoggedInUser?: boolean;
}

const DisplayPanel: React.FC<DisplayPanelProps> = ({
  result,
  displayedImage,
  setDisplayedImage,
  currentImageProps,
  isLoggedInUser = false,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const bottomScrollRef = useRef<HTMLDivElement>(null);

  const scrollToEnd = () => {
    if (bottomScrollRef.current) {
      bottomScrollRef.current.scrollLeft = bottomScrollRef.current.scrollWidth;
    }
  };

  const handlePrevImage = () => {
    const currentIndex = result.image.findIndex(
      (img) => img.image_src === displayedImage
    );
    const prevIndex =
      currentIndex === 0 ? result.image.length - 1 : currentIndex - 1;
    setDisplayedImage(result.image[prevIndex].image_src);
  };

  const handleNextImage = () => {
    const currentIndex = result.image.findIndex(
      (img) => img.image_src === displayedImage
    );
    const nextIndex =
      currentIndex === result.image.length - 1 ? 0 : currentIndex + 1;
    setDisplayedImage(result.image[nextIndex].image_src);
  };

  return (
    <Grid size={{ xs: 12, md: isLoggedInUser ? 8.5 : 6 }}>
      <Box sx={{ flex: 2, position: "relative" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "20px" }}
        >
          {t("TEXT.PHOTOS.YOUR_RESULTS", { count: result.image.length })}
        </Typography>

        <Box
          sx={{
            position: "relative",
            width: "100%",
            maxHeight: "600px",
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            "&:hover .hoverButton": {
              opacity: 1, // Show the button on hover
            },
          }}
        >
          {/* Displayed Image */}
          {/* <img
            src={displayedImage}
            alt="Displayed"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          /> */}

          <ImageWithLoader displayedImage={displayedImage} />

          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              padding: "5px 10px",
              borderRadius: "12px",
            }}
          >
            {currentImageProps?.style_name} {currentImageProps?.space_name}
          </div>

          {/* Left Arrow Button */}
          <Button
            sx={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              width: "40px",
              height: "40px",
              minWidth: "40px",
              borderRadius: "50%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
            onClick={handlePrevImage}
          >
            <ChevronLeft />
          </Button>

          {/* Right Arrow Button */}
          <Button
            sx={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              width: "40px",
              height: "40px",
              minWidth: "40px",
              borderRadius: "50%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
            onClick={handleNextImage}
          >
            <ChevronRight />
          </Button>
          <a
            href={displayedImage}
            download="result.jpg"
            style={{
              textDecoration: "none",
              position: "absolute",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Button
              variant="outlined"
              fullWidth
              className="hoverButton" // This class name is used to target the button in the hover effect
              sx={{
                backgroundColor: customColors.mediumGray,
                border: customColors.mediumGray,
                cursor: "pointer",
                color: "white",
                padding: "5px 10px",
                borderRadius: "8px",
                fontSize: "12px",
                width: "120px",
                opacity: 0, // Start with opacity 0
                transition: "opacity 0.3s ease", // Smooth transition on hover
              }}
              startIcon={<DownloadRounded />}
              //   onClick={async () => {
              //     try {
              //       const response = await fetch(displayedImage, {
              //         mode: "no-cors",
              //       });
              //       const blob = await response.blob();
              //       const url = URL.createObjectURL(blob);

              //       const link = document.createElement("a");
              //       link.href = url;
              //       link.download = "result.jpg";
              //       link.click();

              //       // Clean up the URL object
              //       URL.revokeObjectURL(url);
              //     } catch (error) {
              //       console.error("Image download failed:", error);
              //     }
              //   }}
              //   onClick={() => {
              //     const link = document.createElement("a");
              //     link.href = displayedImage;
              //     link.download = "result.jpg"; // Set the filename for download
              //     document.body.appendChild(link); // Append link to the document body
              //     link.click(); // Programmatically click the link to start download
              //     document.body.removeChild(link); // Remove link after downloading
              //   }}

              onClick={async () => {
                const link = document.createElement("a");
                link.href = displayedImage;
                link.download = "downloaded-image";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              {t("TEXT.PHOTOS.DOWNLOAD")}
            </Button>
          </a>
        </Box>

        <Box
          ref={bottomScrollRef}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            marginBottom: isMobile ? "2px" : "20px",
            overflowX: "scroll",
            width: "100%",
            maxWidth: "1000px",
            paddingBottom: "12px",
            scrollbarWidth: "thin",
            scrollbarColor: `${customColors.gold} ${customColors.darkGray}`,
            "&::-webkit-scrollbar": {
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: customColors.gold,
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: customColors.darkGray,
            },
          }}
        >
          {result.image.map((img, idx) =>
            img.image_src.length === 0 ? (
              <Box
                key={`placeholder-${idx}`} // Include index in key to help React track changes
                sx={{
                  width: "80px", // Ensures width consistency for loader placeholders
                  height: "60px",
                  minWidth: "80px", // Ensures consistent width
                  border: `1px solid ${customColors.lightGray}`,
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  sx={{
                    animationDuration: "7s",
                    width: "40px", // Optional loader size
                  }}
                />
              </Box>
            ) : (
              <img
                key={`${img.id}-${idx}`} // Use both id and index for unique key
                src={img.image_src}
                onError={(e) => {
                  (e.target as HTMLImageElement).src = PLACEHOLDER_IMAGE;
                }}
                alt={`Result ${idx + 1}`}
                style={{
                  width: "80px",
                  height: "60px",
                  border:
                    img.image_src === displayedImage
                      ? `2px solid ${customColors.gold}`
                      : "0px",
                  boxShadow:
                    img.image_src === displayedImage
                      ? "0 0px 6px 4px rgba(235, 196, 127, 0.2)"
                      : "0 0px 6px 4px rgba(77, 208, 225, 0)",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => setDisplayedImage(img.image_src)}
              />
            )
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default DisplayPanel;
