import { ENDPOINTS } from "../endpoints";

interface OrderParams {
  image: File; // The image file
  removeFurniture: boolean;
  spaceName: string;
  styleId: string;
  widgetId: string;
}

export type PostOrderResponse = {
  order_id: string;
};

// Adjusted to accept headers
export const createOrder = async (
  { image, removeFurniture, spaceName, styleId, widgetId }: OrderParams,
  headers: { Authorization: string }
): Promise<PostOrderResponse> => {
  const formData = new FormData();
  formData.append("image", image); // `Image` is the expected field name on the server
  formData.append("remove_furniture", removeFurniture.toString());
  formData.append("space_name", spaceName);
  formData.append("style_id", styleId);
  formData.append("widget_id", widgetId);

  try {
    const response = await fetch(ENDPOINTS.ORDER, {
      method: "POST",
      headers, // Pass the Authorization header here
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to create order");
    }

    // Parse the response JSON and cast it to PostOrderResponse
    const data: PostOrderResponse = await response.json();

    return data; // Now it returns the parsed PostOrderResponse object
  } catch (error) {
    console.error("Error creating order:", error);
    throw error;
  }
};
