import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import {
  GetOrderResultResponse,
  ImageResponse,
} from "../../api/services/orderResultService";
import { FormPanel } from "./UploadPhotoFormPanel";
import Grid from "@mui/material/Grid2";
import {
  ArrowBackIosRounded,
  ArrowForwardRounded,
  ChevronLeft,
  ChevronRight,
  DownloadRounded,
  LockOpenOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import { useTranslation } from "react-i18next";
import { customColors } from "../../theme";
import { PLACEHOLDER_IMAGE } from "../../constatnts";
import { usePostOrderGenerateMore } from "../../hooks/usePostOrderGenerateMore";
import PhotoAdPanel from "./PhotoAdPanel";
import ImageWithLoader from "../ImageWithLoader";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import DisplayPanel from "./DisplayPanel";

interface Props {
  orderId: string;
  result: GetOrderResultResponse;
  morePicturesRequested: boolean;
  setMorePicturesRequested: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PhotoDisplay: React.FC<Props> = ({
  orderId,
  result,
  morePicturesRequested,
  setMorePicturesRequested,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const generateMoreAPI = usePostOrderGenerateMore();

  useEffect(() => {
    if (generateMoreAPI.orderSuccess && !morePicturesRequested) {
      setMorePicturesRequested(true);
      setMorePicsLoading(false);
    }
  }, [generateMoreAPI.orderSuccess, generateMoreAPI.isLoading]);

  const user = auth.currentUser;
  const isLoggedInUser = user && user.emailVerified;
  const [orderSent, setOrderSent] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [morePicsLoading, setMorePicsLoading] = useState<boolean>(false);
  const [beforeImageFile, setBeforeImageFile] = useState<File | null>(null);
  const [displayedImage, setDisplayedImage] = useState<string>(
    result.image ? result.image[0].image_src : ""
  );

  const navigate = useNavigate();

  const handleGenerateMore = (
    removeFurniture: boolean,
    spaceName: string,
    styleId: string,
    widgetId?: string
  ) => {
    setMorePicsLoading(true);
    generateMoreAPI.handleGetOrderGenerateMore({
      orderId: orderId,
      remove_furniture: removeFurniture,
      space_name: spaceName,
      style_id: styleId,
      widget_id: widgetId || "65b4e95ec2b80c47dfe933ab",
    });
  };

  if (!beforeImageFile) {
    fetch(result.before_image_src)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch image");
        }
        return res.blob();
      })
      .then((blob) => {
        const file = new File(
          [blob],
          "https://upload.wikimedia.org/wikipedia/commons/b/b1/Missing-image-232x150.png",
          {
            type: "image/jpeg",
          }
        );
        setBeforeImageFile(file); // Store the selected gallery image as a File
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  }

  const settingsPAnel = (
    <Grid size={{ xs: 12, md: 2.5 }}>
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "20px" }}
        >
          {t("TEXT.PHOTOS.INPUT_PHOTO")}
        </Typography>
        <img
          src={result.before_image_src}
          alt="Before"
          style={{
            width: "100%",
            borderRadius: "8px",
            marginBottom: "20px",
            border:
              result.before_image_src === displayedImage
                ? "2px solid " + customColors.gold
                : "0px",
            boxShadow:
              result.before_image_src === displayedImage
                ? "0 0px 6px 4px rgba(235, 196, 127, 0.2)"
                : "0 0px 6px 4px rgba(77, 208, 225, 0)",

            cursor: "pointer",
          }}
          onError={(e) => {
            (e.target as HTMLImageElement).src = PLACEHOLDER_IMAGE;
          }}
          onClick={() => setDisplayedImage(result.before_image_src)}
        />

        <FormPanel
          // image={beforeImageFile}
          handleCreateOrder={handleGenerateMore}
          isDisabled={generateMoreAPI.isLoading || morePicsLoading}
          setIsError={setIsError}
          // onSubmit={() => setOrderSent(true)}
          actionText={t("TEXT.PHOTOS.GENERATE_MORE")}
          isLoading={
            generateMoreAPI.isLoading ||
            morePicsLoading ||
            morePicturesRequested
          }
          shouldHideFurnitureRemoval={true}
        />
      </Box>
    </Grid>
  );

  const currentImageProps = result.image.find(
    (image: ImageResponse) => image.image_src === displayedImage
  );

  const displayPanel = (
    <DisplayPanel
      result={result}
      displayedImage={displayedImage}
      setDisplayedImage={setDisplayedImage}
      currentImageProps={currentImageProps}
      isLoggedInUser={isLoggedInUser || false}
    />
  );

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: isMobile ? "20px" : "40px",
        gap: "30px",
        backgroundColor: customColors.semiDarkGray,
        margin: isMobile ? "0px" : "0px",
        borderRadius: "12px",
        maxWidth: "1400px",
        width: "100%",
      }}
    >
      {isMobile ? (
        <>
          {displayPanel} {settingsPAnel}
        </>
      ) : (
        <>
          {settingsPAnel}
          {displayPanel}{" "}
        </>
      )}

      {isLoggedInUser ? <></> : <PhotoAdPanel />}
    </Grid>
  );
};

export default PhotoDisplay;
