import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next"; // Import translation hook

export const ErrorComponent: React.FC = () => {
  const { t } = useTranslation(); // Use translation hook
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Box
      sx={{
        p: isMobile ? 2 : 4,
        borderRadius: "10px",
        maxWidth: "1200px",
        minHeight: isMobile ? "300px" : "500px",
        margin: "0 auto",
        display: "flex", // enable flexbox
        flexDirection: "column", // stack elements vertically
        justifyContent: "center", // horizontal centering
        alignItems: "center", // vertical centering
        textAlign: "center", // center text
      }}
    >
      <Typography variant="h6" gutterBottom>
        {t("TEXT.ERROR.TITLE")}
      </Typography>
      <Button variant="contained" onClick={() => window.location.reload()}>
        {t("TEXT.ERROR.REFRESH_BUTTON")}
      </Button>
    </Box>
  );
};
