import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebaseConfig"; // Assume Firebase config is set
import { customColors } from "../../theme";

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // State for email
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // Validate email format
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  // Handle email input change
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  // Handle forgot password form submission
  const handleForgotPassword = async () => {
    if (!email) {
      setMessage(t("TEXT.AUTH.EMAIL_REQUIRED_FOR_RESET"));
      return;
    }

    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(t("TEXT.AUTH.RESET_LINK_SENT"));
    } catch (error: any) {
      if (error.code === "auth/user-not-found") {
        setMessage(t("TEXT.AUTH.USER_NOT_FOUND"));
      } else {
        setMessage(t("TEXT.AUTH.RESET_FAILED"));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: { xs: "20px", md: "60px", sx: "100px" },
      }}
    >
      <Typography component="h1" variant="h5">
        {t("TEXT.AUTH.FORGOT_PASSWORD_TITLE")}
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1, mb: 2 }}>
        {t("TEXT.AUTH.FORGOT_PASSWORD_SUBTITLE")}
      </Typography>
      {message && (
        <Typography
          color={message.includes("SENT") ? "primary" : "error"}
          sx={{ mb: 2 }}
        >
          {message}
        </Typography>
      )}
      <Box
        component="form"
        sx={{ width: "100%", maxWidth: 400 }}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          handleForgotPassword();
        }}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={t("TEXT.AUTH.EMAIL")}
          value={email}
          onChange={handleEmailChange}
          error={!isEmailValid}
          helperText={!isEmailValid ? t("TEXT.AUTH.INVALID_EMAIL") : ""}
          autoComplete="email"
          autoFocus
          sx={{
            input: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "white" },
              "&:hover fieldset": { borderColor: "white" },
              "&.Mui-focused fieldset": { borderColor: "white" },
            },
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!isEmailValid || loading}
          sx={{
            my: 2,
            "&.Mui-disabled": {
              backgroundColor: customColors.mediumGray,
              color: customColors.gold,
            },
          }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            t("TEXT.AUTH.RESET_PASSWORD")
          )}
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => navigate("/login")}
          sx={{ mb: 2 }}
        >
          {t("TEXT.AUTH.BACK_TO_LOGIN")}
        </Button>
      </Box>
    </Box>
  );
};
