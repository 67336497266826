import { CircularProgress } from "@mui/material";
import React from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import { useTranslation } from "react-i18next";

interface ImageComparisonProps {
  beforeImage?: HTMLImageElement | null;
  afterImage?: HTMLImageElement | null;
}

const ImageComparison: React.FC<ImageComparisonProps> = ({
  beforeImage,
  afterImage,
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        margin: "0 auto",
        borderRadius: "15px",
        overflow: "hidden",
        aspectRatio: "3 / 2",
      }}
    >
      {beforeImage && afterImage ? (
        <>
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage src={beforeImage.src} alt="Before" />
            }
            itemTwo={
              <ReactCompareSliderImage src={afterImage.src} alt="After" />
            }
          />
          {/* Before Label */}
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
          >
            {t("TEXT.BEFORE")}
          </div>
          {/* After Label */}
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
            }}
          >
            {t("TEXT.AFTER")}
          </div>
        </>
      ) : (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </div>
  );
};

export default ImageComparison;
